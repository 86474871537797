import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { PATHS } from "../utils/constants";
import ProtectedRoutes from "./ProtectedRoute";

const MenuBar = lazy(() => import("../Pages/NavMenu/NavMenu"));
const LoginPage = lazy(() => import("../Pages/LoginPage/Login"));
const Dash = lazy(() => import("../Pages/Dashboard/Index"));
const Passportlinechart = lazy(() => import("../Pages/Dashboard/Passportlinechart"));

const Users = lazy(() => import("../components/Users/Users"));
const UserEdit = lazy(() => import("../components/Users/UserEdit"));
const IdentityRecords = lazy(() => import("../components/IdentityRecords/IdentityRecords"));
const Kyc = lazy(() => import("../components/IdentityRecords/Kyc"));
const Transactions = lazy(() => import("../components/IdentityRecords/Transactions"));
const IdentityRecordsView = lazy(() => import("../components/IdentityRecords/IdentityRecordsView"));
const Roles = lazy(() => import("../components/Roles/index"));
const Auditdashboard = lazy(() => import("../Pages/Dashboard/AuditDashboard"));
const Audit = lazy(() => import("../components/Audit"));
const Management = lazy(() => import("../components/Management/Index"));
const ManagementView = lazy(() => import("../components/Management/View"));
const ManagementPending = lazy(() => import("../components/Management/Pending"));
const ManagementCompleted = lazy(() => import("../components/Management/Completed"));
const Cancel = lazy(() => import("../components/Management/Cancel"));
const RoleView = lazy(() => import("../components/Roles/View"));
const OTP = lazy(() => import("../Pages/LoginPage/Otp"));
const ResetPassword = lazy(() => import("../Pages/LoginPage/PasswordReset"));
const ChangePassword = lazy(() => import("../Pages/LoginPage/ChangePassword"));
const ResetChangePassword = lazy(() => import("../Pages/LoginPage/ResetchangePassword"));
const ForgotPassword = lazy(() => import("../Pages/LoginPage/ForgotPassword"));
const Verifications = lazy(() => import("../Pages/Dashboard/VerificationView"));
const ValidityChecks = lazy(() => import("../Pages/Dashboard/ValidityChecks"));
const VarificationCheckResult = lazy(() => import("../Pages/Dashboard/VerificationCheckResult"));
const Metrcis = lazy(() => import("../Pages/Dashboard/MetricsView"));
const Linechat = lazy(() => import("../Pages/Dashboard/LineChatview"));
const Auditeventmetrics = lazy(() => import("../Pages/Dashboard/Auditeventmetrics"));
const NewDashboard = lazy(() => import("../Pages/Dashboard/Dashboard3"));
const Reportuser = lazy(() => import("../components/Reports/Reportusers"));
const Reportkyc = lazy(() => import("../components/Reports/Reportkyc"));
const Reporteidcard = lazy(() => import("../components/Reports/Reporteidcard"));
const Auditdata = lazy(() => import("../components/Audit/Auditdata"));
const Reports = lazy(() => import("../components/Reports/Index"));
// const Sample = lazy(() => import("../Pages/Dashboard"));


const HandleRoutes = () => (
  <Routes>
    {/* Public Routes */}
    <Route path='/' element={<LoginPage />} />
    <Route path='/Otp' element={<OTP />} />
    <Route path='/ResetPassword' element={<ResetPassword />} />
    <Route path='/ChangePassword' element={<ChangePassword />} />
    <Route path='/ResetChangePassword' element={<ResetChangePassword />} />
    <Route path='/ForgotPassword' element={<ForgotPassword />} />
    {/* <Route path='/*' element={<NotFound />} /> */}
    {/* Private Routes */}
    <Route element={<ProtectedRoutes />}>
      <Route element={<MenuBar />}>
        {/* <Route path={PATHS.Sample} element={<Sample />} /> */}
        <Route path={PATHS.Dashboard} element={<Dash />} />
        <Route path={PATHS.Dashboard3} element={<NewDashboard />} />
        <Route path={PATHS.Passportlinechart} element={<Passportlinechart />} />
        <Route path={PATHS.Reports} element={<Reports />} />
        
        <Route path={PATHS.Reportusers} element={<Reportuser />} />
        <Route path={PATHS.Reportkyc} element={<Reportkyc />} />
        <Route path={PATHS.Reporteidcard} element={<Reporteidcard />} />
        <Route path={PATHS.Auditeventmetrics} element={<Auditeventmetrics />} />
        <Route path={PATHS.VerficationView} element={<Verifications />} />
        <Route path={PATHS?.ValidityChecks} element={<ValidityChecks />} />
        <Route path={PATHS?.VarificationCheckResult} element={<VarificationCheckResult />} />
        <Route path={PATHS?.Metrcis} element={<Metrcis />} />
        <Route path={PATHS?.Linechat} element={<Linechat />} />
        <Route path={PATHS.userManagement.root} element={<Roles />} />
        <Route path={PATHS.User} element={<Users />} />
        <Route path={PATHS.UsersEdit} element={<UserEdit />} />
        <Route path={PATHS.IdentityRecords} element={<IdentityRecords />} />
        <Route path={PATHS.Kyc} element={<Kyc />} />
        <Route path={PATHS.Transactions} element={<Transactions />} />
        <Route path={PATHS.IdentityRecordsView} element={<IdentityRecordsView />} />
        <Route path={PATHS.Roles} element={<Roles />} />
        <Route path={PATHS.Audit} element={<Audit />} />
        <Route path={PATHS.Auditdata} element={<Auditdata />} />
        <Route path={PATHS.Auditdashboard} element={<Auditdashboard />} />
        <Route path={PATHS.Management.root} element={<Management />} />
        <Route path={PATHS.ManagementView} element={<ManagementView />} />
        <Route path={PATHS.Management.pending} element={<ManagementPending />} />
        <Route path={PATHS.Management.completed} element={<ManagementCompleted />} />
        <Route path={PATHS.Management.Cancel} element={<Cancel />} />
        <Route path={PATHS.Roleview} element={<RoleView />} />
      </Route>
    </Route>
  </Routes>
);

export default HandleRoutes;
