import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// Function to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decoding the token
    const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now(); // Get current time in milliseconds

    return currentTime > expirationTime; // Returns true if expired
  } catch (error) {
    return true; // Token is invalid or corrupted
  }
};

const useAuth = () => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("tokenotp");

    // Check if token exists and is not expired
    if (token && !isTokenExpired(token)) {
      setAuth(true); // Authenticated
    } else {
      sessionStorage.removeItem("tokenotp"); // Remove expired/invalid token
      setAuth(false); // Not authenticated
    }

    // Get the remaining time from sessionStorage or default to 5 minutes
    const remainingTime = parseInt(sessionStorage.getItem("remainingTime"), 10) || 1500000; // 15 minutes
                                                                                 

    // Set a timer to log out after the remaining time
    const logoutTimer = setTimeout(() => {
      sessionStorage.removeItem("tokenotp"); // Remove the token
      sessionStorage.removeItem("remainingTime")
      setAuth(false); // Log out the user by setting auth to false
    }, remainingTime);

    // Save the remaining time in sessionStorage
    sessionStorage.setItem("remainingTime", remainingTime);

    // Cleanup the timer when the component is unmounted or on auth change
    return () => clearTimeout(logoutTimer);
  }, []);

  return auth;
};

const ProtectedRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  // If auth is null, the component is still loading, so we wait to render the protected routes
  if (auth === null) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  // If authenticated, render the child routes (`<Outlet />`); otherwise, navigate to login page
  return auth ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoutes;
